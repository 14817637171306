(function(window, document, undefined) {
'use strict';
/// here is where the dragon lives
$(function () {

    // navbar-fixed-top
    $(document).scroll(function (e) {
        var scrollTop = $(document).scrollTop();
        if (scrollTop > 0) {
            // debug
            // console.log(scrollTop);
            $('.navbar').removeClass('navbar-static-top').addClass('navbar-fixed-top');
        } else {
            $('.navbar').removeClass('navbar-fixed-top').addClass('navbar-static-top');
        }
    });

    // close one pager navigation
    var navMain = $(".navbar-collapse");
    navMain.on("click", "a:not([data-toggle])", null, function () {
        navMain.collapse('hide');
    });
});

})(window, document);
